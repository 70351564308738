import React from "react";
import { PerformanceIndexPageProps, PERFORMANCE_INDEX_COLUMNS } from "../lib/";
import { navigate } from "gatsby";
import PageLayout from "./PageLayout";

export default ({ upcoming, past }: PerformanceIndexPageProps) => {
  return (
    <PageLayout>
      <article>
        <h1>Upcoming ({upcoming.length})</h1>
        <section aria-label="Past Events">
          <h1>Past ({past.length})</h1>
          <table>
            <thead>
              {PERFORMANCE_INDEX_COLUMNS.map(({ title, key }) => (
                <td key={key}>{title}</td>
              ))}
            </thead>
            <tbody>
              {past.map((event) => (
                <tr
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(event.slug)}
                >
                  {PERFORMANCE_INDEX_COLUMNS.map(({ key }) => (
                    <td aria-label={`${key}-column`} key={key}>
                      {key === "hasRecordings"
                        ? event[key]
                          ? "yes"
                          : ""
                        : event[key]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </article>
    </PageLayout>
  );
};
